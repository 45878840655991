import React from "react"
import { TextHeader } from "../../../components/globals"
import {
  Input,
  Select,
  SubSection,
  SubTitle,
  View,
  AdvancedButton,
} from "../style"

const Step3 = ({ CYLActive, activeCYL, order, state }) => {
  const [advancedMode, setAdvancedMode] = React.useState(false);
  const isPreset = state?.title !== undefined
  return (
    <View key="view-3">
      <SubTitle>Styrker høyre øye</SubTitle>
      <TextHeader>* Påkrevd felt</TextHeader>
      <SubSection>
        <Input
          required
          id="merke-høyre"
          defaultValue={order["merke-høyre"]}
          disabled={isPreset}
          name="merke-høyre"
          label="Foretrukket merke *"
          placeholder="Foretrukket merke"
        />
        <Select
          required
          id="styrke-høyre"
          image={2}
          defaultValue={order["styrke-høyre"] ?? 0}
          name="styrke-høyre"
          label="Styrke *"
          type="number"
          placeholder="0.00"
          from={-12}
          to={12}
          step={0.25}
        />
      </SubSection>

      <SubSection>
        <Select
          required
          id="pakkestørrelse-høyre"
          disabled={isPreset}
          defaultValue={order["pakkestørrelse-høyre"]}
          name="pakkestørrelse-høyre"
          label="Pakkestørrelse *"
          type="number"
          placeholder="6 / 30 / 90"
        >
          <option value={6}>6</option>
          <option value={30}>30</option>
          <option value={90}>90</option>
        </Select>
        <Select
          required
          id="antall-høyre"
          defaultValue={order["antall-høyre"]}
          name="antall-høyre"
          label="Antall pakker *"
          type="number"
          placeholder="0"
          from={1}
          to={4}
          step={1}
        />
      </SubSection>
      <SubSection>
        {advancedMode ? (
          <>
            <Select
              label="CYL"
              id="cyl-høyre"
              image={2}
              onChange={() => activeCYL(true)}
              defaultValue={order["cyl-høyre"]}
              placeholder
              name="cyl-høyre"
              type="number"
              from={-4}
              to={-0.75}
              step={0.25}
            />

            <Select
              label="AXE"
              id="axe-høyre"
              defaultValue={order["axe-høyre"]}
              placeholder
              name="axe-høyre"
              type="number"
              from={10}
              to={180}
              step={10}
            />
            <Input
              label="ADD"
              defaultValue={order["add-høyre"]}
              id="ADD-høyre"
              name="ADD-høyre"
              key="ADD-høyre"
              placeholder=""
            />
          </>
        ) : (
          <AdvancedButton onClick={() => setAdvancedMode(true)}>
            Avanserte valg (CYL / ADD / AXE) ⌄
          </AdvancedButton>
        )}
      </SubSection>
    </View>
  )
}
export default Step3
