import React from "react"
import { SubTitle, SuccessImg, Text, View } from "../style"
import success from "../../../images/confirmed.svg"

const Step6 = () => {
  return (
    <View key="view-6">
      <SuccessImg src={success} />
      <SubTitle center>Bestilling sendt</SubTitle>
      <Text center max={500}>
        Din bestilling er nå mottatt! Vi tar kontakt så fort bestillingen din er
        klar til å bli plukket opp.
      </Text>
    </View>
  )
}
export default Step6
