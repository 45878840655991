const S = (field) => field ? String(field) : undefined;

export const exportOrder = order => {
  const contact = {
    navn: order.name,
    email: order.email,
    telefon: order.phone,
    fodselsdato: new Date(order.fodselsdato).toLocaleDateString("no-nb"),
    annet: order.annet,
  }
  let finalOrder = [
    {
      Type: S(order["merke-høyre"]),
      Styrke: S(order["styrke-høyre"]),
      CYL: S(order["cyl-høyre"]),
      AXE: S(order["axe-høyre"]),
      ADDValue: S(order["ADD-høyre"]),
      Pakkestorrelse: S(order["pakkestørrelse-høyre"]),
      Antall: S(order["antall-høyre"]),
    },
  ]
  if (order["merke-venstre"]) {
    finalOrder = [
      ...finalOrder,
      {
        Type: S(order["merke-venstre"]),
        Styrke: S(order["styrke-venstre"]),
        CYL: S(order["cyl-venstre"]),
        AXE: S(order["axe-venstre"]),
        ADDValue: S(order["ADD-venstre"]),
        Pakkestorrelse: S(order["pakkestørrelse-venstre"]),
        Antall: S(order["antall-venstre"]),
      },
    ]
  }
  return { contact, Product: finalOrder, finnmeg: order.findMe ?? false }
}

export const getStepText = step => {
  switch (step) {
    case 0:
      return "Fortsett"
    case 1:
      return "Fortsett"
    case 2:
      return "Fortsett"
    case 3:
      return "Fortsett"
    case 4:
      return "Fullfør linsebestilling"
    default:
      return null
  }
}
