import React from "react"
import { Input, SubSection, SubTitle, TextArea, View } from "../style"

const Step1 = ({ order, }) => {
  return (
    <View key="view-1">
      <SubTitle>Kontaktdetaljer</SubTitle>
      <SubSection>
        <Input
          required
          defaultValue={order["name"]}
          label="Navn *"
          type="text"
          id="name"
          name="name"
          key="name"
          autocomplete="name"
          placeholder="Ola Normann"
        />
        <Input
          required
          defaultValue={order["email"]}
          label="Email *"
          id="email"
          name="email"
          type="email"
          key="email"
          autocomplete="email"
          placeholder="mail@minmail.no"
        />
        <Input
          required
          defaultValue={order["phone"]}
          label="Telefon *"
          id="phone"
          name="phone"
          type="tel"
          key="tel"
          autocomplete="tel"
          placeholder="99 99 99 99"
        />
        <Input
          required
          defaultValue={order["fodselsdato"]}
          placeholder="DD-MM-YYYY"
          label="Fødseldato *"
          id="fodselsdato"
          name="fodselsdato"
          type="date"
          autocomplete="bday"
          key="fodselsdato"
        />
      </SubSection>
      <SubSection>
        <TextArea
          label="Annet"
          defaultValue={order["annet"]}
          id="annet"
          name="annet"
          key="annet"
          placeholder="Andre kommentarer for bestillingen"
        />
      </SubSection>
    </View>
  )
}
export default Step1
