import React, { useState } from "react"
import axios from "axios"
import { exportOrder, getStepText } from "./helpers"
import { NextStep, OrderForm, ToggleSetting } from "./style"
import {
  InsideWrapper,
  PageTitle,
  SubHeader,
  updateGTM,
  pushGTMEvent,
  GTMConvertProduct,
} from "../../components/globals"
import Step1 from "./step/step1"
import Step2 from "./step/step2"
import Step3 from "./step/step3"
import Step4 from "./step/step4"
import Step5 from "./step/step5"
import Step6 from "./step/step6"
import ProductWidget from "./ProductWidget"

const LinseContent = ({ locationState }) => {
  const [step, setStep] = useState(0)
  const [order, setOrder] = useState({})
  const [CYLActive, activeCYL] = useState(false)
  const [disabled, setDisableSubmit] = useState(false);

  const sendOrder = obj => {
    const data = {
      ...obj.contact,
      fodselsdato: new Date(order.fodselsdato).toISOString(),
      Product: obj.Product,
      finnmeg: obj.finnmeg,
    }
    return axios.post(`${process.env.STRAPI_API_URL}/api/orders`, { "data": data }, {
      headers: { "Content-Type": "application/json" },
    })
  }

  const onSubmit = event => {
    event.preventDefault()

    /**
     * When ready to send order
     */
    if (step === 4) {
      const newOrder = exportOrder(order)
      setDisableSubmit(true);
      sendOrder(newOrder).then(res => {
        updateGTM("konvertertBestilling", res)()
        if (locationState?.title !== undefined) {
          pushGTMEvent("purchase", [GTMConvertProduct(locationState)])
        }
        setStep(step + 1)
      }).finally(() => setDisableSubmit(false));
      return
    }
    /**
     * Standard handling of next step
     */
    const formData = new FormData(event.target)
    const data = Object.fromEntries(formData)
    setOrder({ ...order, ...data })
    let stepIncrease = step + 1
    if (step === 0 && !!locationState) {
      stepIncrease += 1
    }
    setStep(stepIncrease)
  }
  const onStepBack = () => {
    if (step > 0) setStep(step - 1)
    if (step === 2 && !!locationState) setStep(step - 2)
  }
  const getStep = () => {
    switch (step) {
      case 0:
        return (
          <Step1
            order={order}
            setOrder={setOrder}
            setStep={setStep}
            step={step}
          />
        )
      case 1:
        return (
          <Step2
            order={order}
            setOrder={setOrder}
            setStep={setStep}
            step={step}
          />
        )
      case 2:
        return (
          <Step3
            state={locationState}
            order={order}
            setOrder={setOrder}
            setStep={setStep}
            step={step}
            CYLActive={CYLActive}
            activeCYL={activeCYL}
          />
        )
      case 3:
        return (
          <Step4
            state={locationState}
            order={order}
            setOrder={setOrder}
            setStep={setStep}
            step={step}
            CYLActive={CYLActive}
            activeCYL={activeCYL}
          />
        )
      case 4:
        return <Step5 order={order} />
      case 5:
        return <Step6 order={order} />
      default:
        return null
    }
  }

  React.useEffect(() => {
    if (locationState) {
      const merke = locationState.title
      const size = locationState.packagesize
      setOrder(order => ({
        ...order,
        "merke-høyre": merke,
        "merke-venstre": merke,
        "pakkestørrelse-høyre": size,
        "pakkestørrelse-venstre": size,
      }))
    }
  }, [locationState])

  return (
    <InsideWrapper>
      <PageTitle>Klikk og Hent</PageTitle>
      <SubHeader>
        Bestill linsene dine her, så gir vi deg en beskjed når de er klare til å
        bli plukket opp hos oss.
      </SubHeader>
      <ToggleSetting style={{ textAlign: "left" }} onClick={onStepBack}>
        {step > 0 && step < 5 ? "‹ Tilbake " : " "}
      </ToggleSetting>
      <OrderForm onSubmit={onSubmit}>
        <ProductWidget {...locationState} />
        {getStep(step)}
        {step < 5 && step !== 1 ? (
          <NextStep type="submit" disabled={disabled}>{getStepText(step)}</NextStep>
        ) : null}
      </OrderForm>
    </InsideWrapper>
  )
}
export default LinseContent
