import React from "react"
import {
  Column,
  ImageHolder,
  ProductImage,
  ProductPrice,
  ProductTitle,
  WidgetWrapper,
} from "./style"

const ProductWidget = locationState => {
  if (
    locationState?.title === undefined ||
    !Object.keys(locationState).length
  ) {
    return null
  }
  const [image] = locationState.images

  return (
    <WidgetWrapper>
      <ImageHolder>
        <ProductImage image={image.localFile?.childImageSharp?.gatsbyImageData} />
      </ImageHolder>
      <Column>
        <ProductTitle>{locationState.title}</ProductTitle>
        <ProductPrice>{locationState.price},-</ProductPrice>
      </Column>
    </WidgetWrapper>
  );
}
export default ProductWidget
