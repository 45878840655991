import React from "react"
import {
  OrderTable,
  SmallTitle,
  SubSection,
  SubTitle,
  TableWrapper,
  Text,
  View,
} from "../style"
import { exportOrder } from "../helpers"

const Step5 = ({ order }) => {
  const {
    contact: { finnmeg, ...contact },
    Product: Order,
  } = exportOrder(order)
  return (
    <View key="view-5">
      <SubTitle>Oppsummering</SubTitle>
      <SubSection column>
        <SmallTitle>Kontaktinfo</SmallTitle>
        <SubSection column padding>
          {Object.keys(contact).map(key => (
            <Text key={key}>
              <b>{key}:</b> {contact[key]}
            </Text>
          ))}
        </SubSection>
        <SmallTitle>Bestilling</SmallTitle>
        {order.findMe ? (
          <SubSection>
            <Text>Vi finner din siste bestilling i registeret.</Text>
          </SubSection>
        ) : (
          <TableWrapper>
            <OrderTable>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Styrke</th>
                  <th>CYL</th>
                  <th>AXE</th>
                  <th>ADD</th>
                  <th>Antall pakker</th>
                  <th>Størrelse</th>
                </tr>
              </thead>
              <tbody>
                {Order?.map((orderobject, i) => (
                  <tr key={`order-${i}`}>
                    <td>{orderobject["Type"]}</td>
                    <td>{orderobject["Styrke"]}</td>
                    <td>{orderobject["CYL"]}</td>
                    <td>{orderobject["AXE"]}</td>
                    <td>{orderobject["ADD"]}</td>
                    <td>{orderobject["Antall"]}</td>
                    <td>{orderobject["Pakkestorrelse"]}</td>
                  </tr>
                ))}
              </tbody>
            </OrderTable>
          </TableWrapper>
        )}
      </SubSection>
    </View>
  )
}
export default Step5
