import React from "react"
import Layout from "../components/layout"
import LinseContent from "../pageContent/linseContent"

const IndexPage = ({ location, data }) => {
  return (
    <Layout
      title="Bestill linser"
      description="Bestill linsene dine her, så gir vi deg en beskjed når de er klare til å bli plukket opp hos oss."
      keywords={["bestill", "linser", "lambertseter", "nordstrand", "optikk"]}
    >
      <LinseContent locationState={location.state} />
    </Layout>
  )
}

export default IndexPage
