import React from "react"
import styled, { keyframes } from "styled-components"

export const OrderForm = styled.form`
  max-width: 1280px;
  width: 100%;
  background: #0b0c14;
  border-radius: 1em 1em 0 0;
  padding: 1em;
  display: flex;
  flex-direction: column;
  min-height: 85vh;
`
export const SubSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-flow: ${({ column }) => (column ? "column" : "row wrap")};
  padding: 0 1.5em;
  @media screen and (max-width: 991px) {
    padding: ${({ padding }) => (padding ? "0 1em 1em 1em" : 0)};
  }
`
export const ActualInput = styled.input`
  background: white;
  padding: 0.4em 1em;
  border: 0;
  border-radius: 5px;
  letter-spacing: 0.7px;
  line-height: 1.4;
  width: 100%;
`
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  min-width: 200px;
  max-width: 350px;
  margin: 0.5em 0.75em;
  & > label {
    color: white;
  }
  @media screen and (max-width: 991px) {
    flex-grow: 1;
    margin: 0.5em 0;
    max-width: 100%;
  }
`
const ActualSelect = styled.select`
  background: white;
  padding: 0.4em 1em;
  border: 0;
  border-radius: 5px;
  letter-spacing: 0.7px;
  line-height: 1.4;
  & > option {
    &:disabled {
      color: #ccc;
    }
    padding: 0 0.2em;
  }
`
export const SubTitle = styled.h2`
  letter-spacing: 0.7px;
  line-height: 1.4;
  color: white;
  margin: 0.25em 0;
  font-weight: ${({ thin }) => (thin ? 300 : 700)};
  ${({ center }) => (center ? "text-align:center;" : null)}
`
export const ToggleSection = styled(SubSection)`
  margin: 2em 1em;
  width: 100%;
  justify-content: center;
`
const floatIn = keyframes`
    from{
        opacity:0;
        transform:translateX(50%);
    }
    to{
        opacity:1;
        transform:translateX(0%)
    }
`
export const View = styled.div`
  animation: ${floatIn} 0.3s ease;
`
export const Notifier = styled.button`
  border-radius: 5px;
  letter-spacing: 0.7px;
  padding: 0.5em 1em;
  margin: 0 0.5em;
  width: 100%;
  text-align: left;
  border: 0;
  background: #494a57;
  color: white;
  cursor: pointer;
  & > span {
    line-height: 1em;
    border: 0;
    color: #494a57;
    font-weight: 700;
    border-radius: 5px;
    background: white;
    padding: 0 0.5em;
    text-align: center;
    margin: 0 0.5em 0 0;
  }
`
export const ToggleSetting = styled.button`
  border: 0;
  background: transparent;
  font-weight: ${({ active }) => (active ? 500 : 300)};
  letter-spacing: 0.7px;
  color: #405de6;
  flex-shrink: 1;
  cursor: pointer;
  transition: border 0.3s ease;
  border-bottom: 2px solid
    ${({ active }) => (active ? "#405DE6" : "transparent")};
`
export const NextStep = styled.button`
  padding: 0.5em;
  margin: 1em 0 0 0;
  width: 100%;
  max-width: 600px;
  color: white;
  border: 0;
  border-radius: 5px;
  background: #405de6;
  position: sticky;
  bottom: 0.5em;
  cursor: pointer;
  margin-right: 2em;
  @media screen and (min-width: 992px) {
    max-width: 300px;
    align-self: flex-end;
    margin-right: 0;
  }
`
export const Text = styled.h5`
  color: white;
  letter-spacing: 1.6px;
  line-height: 1.4;
  font-weight: 300;
  margin: 0 0 0.8em 0;
  ${({ center }) => (center ? "text-align:center;" : null)}
  ${({ max }) =>
    max ? `max-width:${max}px;margin: 0 auto .8em auto;` : null}
    & > b {
    text-transform: capitalize;
  }
`
export const SmallTitle = styled.h3`
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1.6;
  color: white;
  margin-bottom: 0.5em;
  ${({ center }) => (center ? "text-align:center;" : null)}
`

export const OrderTable = styled.table`
  border-collapse: collapse;
  font-size: inherit;
  margin: 1em 0;
  width: 100%;
  color: white;
  padding: 1em;
  & td {
    text-align: left;
  }
`
export const TableWrapper = styled.div`
  overflow: auto;
`
export const ActualTextArea = styled.textarea`
  background: white;
  padding: 1em;
  border: 0;
  border-radius: 5px;
  letter-spacing: 0.7px;
  line-height: 1.4;
  width: 100%;
`
export const Button = styled.button`
  margin: 0 auto;
  padding: 0.5em 2em;
  border-radius: 3px;
  border: 0;
  cursor: pointer;
`

export const TextAreaWrapper = styled(InputWrapper)`
  flex-shrink: 0;
  flex-grow: 1;
  max-width: 100%;
`
export const AdvancedButton = styled.h5`
  color: white;
  cursor: pointer;
  margin: 1em;
`

export const Input = ({ children, label, ...props }) => (
  <InputWrapper>
    <label>{label}</label>
    <ActualInput {...props} />
  </InputWrapper>
)
export const SuccessImg = styled.img`
  width: 100%;
  max-width: 250px;
  max-height: 250px;
  margin: 1em auto 2em auto;
  left: 0;
  right: 0;
  display: block;
`

export const TextArea = ({ children, label, ...props }) => (
  <TextAreaWrapper>
    <label>{label}</label>
    <ActualTextArea {...props} />
  </TextAreaWrapper>
)
export const Select = ({
  children,
  label,
  from,
  to,
  step,
  placeholder,
  fixed,
  onChange,
  ...props
}) => {
  let arr = [from]
  if (to && from && step)
    Array((to - from) * (1 / step))
      .fill(0)
      .forEach(() => {
        arr.push(
          Number(
            (
              parseFloat(arr[arr.length - 1], 10) + parseFloat(step, 10)
            ).toFixed(2)
          )
        )
      })
  return (
    <InputWrapper>
      <label>{label}</label>
      <ActualSelect {...props} onChange={onChange}>
        {placeholder && (
          <option value={undefined} disabled={typeof placeholder !== "boolean"}>
            {placeholder}
          </option>
        )}
        {children ??
          arr.map(num => (
            <option key={num} value={num}>
              {fixed ? num.toFixed(fixed) : num}
            </option>
          ))}
      </ActualSelect>
    </InputWrapper>
  )
}
