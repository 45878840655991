import React from "react"
import { TextHeader } from "../../../components/globals"
import {
  Input,
  Select,
  SubSection,
  SubTitle,
  ToggleSection,
  ToggleSetting,
  View,
  AdvancedButton,
} from "../style"

const Step4 = ({ activeCYL, order, setOrder, setStep, step, state }) => {
  const [advancedMode, setAdvancedMode] = React.useState(false)
  const onSameAsRight = () => {
    setOrder(order => {
      const clearedData = Object.keys(order).reduce((res, curr) => {
        if (curr.includes("-venstre")) return res;
        return { ...res, [curr]: order[curr] }
      }, {})
      return { ...clearedData, "antall-høyre": Number(clearedData["antall-høyre"]) + 1 }
    })
    setStep(step + 1)
  }
  return (
    <View key="view-4">
      <SubTitle>Styrker venstre øye</SubTitle>
      <TextHeader>* Påkrevd felt</TextHeader>
      <SubSection>
        <Input
          id="merke-venstre"
          defaultValue={order["merke-venstre"]}
          name="merke-venstre"
          label="Foretrukket merke *"
          placeholder="Foretrukket merke"
        />
        <Select
          id="styrke-venstre"
          image={2}
          defaultValue={order["styrke-venstre"] ?? 0}
          name="styrke-venstre"
          label="Styrke *"
          type="number"
          placeholder="0.0"
          from={-12}
          to={12}
          step={0.25}
        />
      </SubSection>
      <SubSection>
        <Select
          id="pakkestørrelse-venstre"
          defaultValue={order["pakkestørrelse-venstre"]}
          name="pakkestørrelse-venstre"
          label="Pakkestørrelse *"
          type="number"
          placeholder="6 / 30 / 90"
        >
          <option value={6}>6</option>
          <option value={30}>30</option>
          <option value={90}>90</option>
        </Select>
        <Select
          id="antall-venstre"
          defaultValue={order["antall-venstre"]}
          name="antall-venstre"
          label="Antall pakker *"
          type="number"
          placeholder="0"
          from={1}
          to={4}
          step={1}
        />
      </SubSection>
      <SubSection>
        {advancedMode ? (
          <>
            <Select
              label="CYL"
              image={2}
              id="cyl-venstre"
              onChange={() => activeCYL(true)}
              defaultValue={order["cyl-venstre"]}
              name="cyl-venstre"
              type="number"
              placeholder
              from={-4}
              to={-0.75}
              step={0.25}
            />
            <Select
              label="AXE"
              id="axe-venstre"
              placeholder
              defaultValue={order["axe-venstre"]}
              name="axe-venstre"
              type="number"
              from={10}
              to={180}
              step={10}
            />
            <Input
              label="ADD"
              defaultValue={order["add-venstre"]}
              id="ADD-venstre"
              name="ADD-venstre"
              key="ADD-venstre"
              placeholder=""
            />
          </>
        ) : (
          <AdvancedButton onClick={() => setAdvancedMode(true)}>
            Avanserte valg (CYL / ADD / AXE) ⌄
          </AdvancedButton>
        )}
      </SubSection>

      <SubSection>
        <ToggleSection>
          <ToggleSetting type="button" onClick={onSameAsRight}>
            Samme som høyre
          </ToggleSetting>
        </ToggleSection>
      </SubSection>
    </View>
  )
}
export default Step4
