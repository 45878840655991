import React from "react"
import { SubHeader } from "../../../components/globals"
import {
  Button,
  SubSection,
  SubTitle,
  ToggleSection,
  ToggleSetting,
  View,
} from "../style"

const Step2 = ({ order, setOrder, setStep, step }) => {
  return (
    <View key="view-2">
      <SubTitle>Bestilt før?</SubTitle>
      <SubHeader reverse>
        Har du vært hos oss før? Da har vi deg i registeret og kan finne din
        siste bestilling.
      </SubHeader>
      <SubSection>
        <ToggleSection>
          <ToggleSetting
            type="button"
            onClick={() => {
              setOrder({ ...order, findMe: true })
              setStep(4)
            }}
          >
            Finn min forrige bestilling
          </ToggleSetting>
        </ToggleSection>
        <Button onClick={() => setStep(step + 1)}>
          Nei, fortsett som vanlig
        </Button>
      </SubSection>
    </View>
  )
}
export default Step2
