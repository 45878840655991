import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";

export const WidgetWrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  background: #efefef;
  border-radius: 0.5em;
  max-width: 300px;
  margin: 1em 0;
  &:after {
    position: absolute;
    content: "I bestilling";
    top: 0;
    right: 0;
    transform: translate(10%, -50%);
    background: #405de6;
    color: white;
    white-space: nowrap;
    padding: 0.25em 0.5em;
    border-radius: 0.25em;
    z-index: 2;
  }
`
export const ProductTitle = styled.h4`
  color: #0b0c14;
  margin-bottom: 0.25em;
`
export const ProductPrice = styled.h5`
  font-weight: 300;
  margin: 0;
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1em;
  justify-content: center;
`
export const ProductImage = styled(GatsbyImage)`
  display: block;
  height: 100%;
  border-radius: 0.25em;
`
export const ImageHolder = styled.div`
  height: 80px;
  width: 80px;
`
